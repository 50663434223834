<template>
    <div>
        <page-main class="top">
            <!--学校标题-->
            <div class="title">
                {{ data.name }}
            </div>
            <!-- 标签 -->
            <div class="tags">
                <el-tag type="warning" color="#fff" style="font-size: 15px;">
                    <i class="el-icon-location"/>
                    地区 · {{ data.area }}
                </el-tag>
                <el-tag type="warning" color="#fff" style="font-size: 15px; margin-left: 20px;">
                    <i class="el-icon-s-flag"/>
                    学硕 · 法硕
                </el-tag>
                <el-tag type="warning" color="#fff" style="font-size: 15px; margin-left: 20px;">
                    <i class="el-icon-s-promotion"/>
                    推荐免试
                </el-tag>
            </div>
        </page-main>
        <el-row>
            <el-col :span="12">
                <page-main title="法学硕士" class="content">
                    <div class="title">
                        招生信息
                    </div>
                    <div class="text">
                        {{ data.lawsEnrollment }}
                    </div>
                    <div class="title">
                        复试信息
                    </div>
                    <div class="text">
                        {{ data.lawsReexamine }}
                    </div>
                </page-main>
            </el-col>
            <el-col :span="12">
                <page-main title="法律硕士" class="content">
                    <div class="title">
                        招生信息
                    </div>
                    <div class="text">
                        {{ data.jurisEnrollment }}
                    </div>
                    <div class="title">
                        复试信息
                    </div>
                    <div class="text">
                        {{ data.jurisReexamine }}
                    </div>
                </page-main>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "detail",
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        if (this.$route.params.id) {
            // 获取单个数据
            this.$api.get('/university/index/getUniversityDetail', {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.data = res.data
            }).catch(err => {
                console.log('单个院校数据获取失败', err)
            })
        } else {
            this.$router.go(-1)
        }

    }
}
</script>

<style scoped lang="scss">
.top {
    padding: 30px;
    border-radius: 10px;
    background: url('../../assets/images/university-bg.png') no-repeat;
    background-size: 100%;
    color: #FFFFFF;

    .title {
        font-size: 25px;
        font-weight: bold;
    }

    .tags {
        margin-top: 20px;
    }
}

.content {
    padding: 30px;
    border-radius: 10px;

    .title {
        font-size: 15px;
        font-weight: bold;
        padding-left: 10px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-left: #f29100 5px solid;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .text {
        line-height: 1.6;
        font-size: 18px;
        margin-bottom: 80px;
        color: #515151;
    }
}
</style>